
export default {
	name: 'craOrgStatistics',
	data() {
		return {
			count1: 0,
			count2: 0,
			count3: 0,
			count4: 0,
			count5: 0,
			x: [],
			y: [],
			token: '',
			userId: '',
			value1: 1,
			value2: 1,
			value3: 1,
			value4: 1,
			options: [{
				value: '1',
				label: '消化科'
			}, {
				value: '2',
				label: '内科'
			}],
			list: [{
				id: '1',
				shortName: '项目名',
				bidName: '1',
				departmentName: '2',
				diseaseName: '3',
				startTime: '4',
				endTime: '5'
			}],
			echar4List: [],
			yeas: '',
			data1: [],
			data2: {},
			data3: [],
			data4: [],
			data5: [],
			data6: [],
			data7: []
		}
	},

	mounted() {
		// this.drawLine();
	},
	created() {
		var _this = this;
		var date = new Date;
		_this.yeas = date.getFullYear();
		this.token = this.getCookie('token')
		this.userId = this.getCookie('userId')
		_this.getProjectsts()
		_this.getData1()
		_this.getData2()
		_this.getData3()
		_this.getData4()
		_this.getData5()
		_this.getData6()
		_this.getData7()
		// _this.getAuditHdCount()
		// _this.getDepartmentList()
	},
	// beforeRouteLeave(to, from, next) {
	// 	to.meta.keepAlive = false;
	// 	next();
	// },
	methods: {
		getProjectsts() {
			var _this = this;
			_this.$http.post(_this.craUrl + '/statisticsSurvey', {}, {
				headers: {
					"token": _this.token
				}
			}).then(function (response) {
				if (response.data.code == 200) {
					_this.count1 = response.data.data.map.projectNum;
					_this.count2 = response.data.data.map.monitorNum;
					_this.count3 = response.data.data.map.monitorHour;
					_this.count4 = response.data.data.map.inspectNum;
					_this.count5 = response.data.data.map.inspectHour;
				} else if (response.data.code == 401) {
					_this.$router.push('/')
				}
			})
		},
		//科室下拉框
		getDepartmentList() {
			var _this = this;
			_this.$http.post(_this.craUrl + '/findDepartmentList', {}, {
				headers: {
					"token": _this.token
				}
			}).then(function (response) {
				if (response.data.code == 200) {
					_this.options = response.data.data.array
				}
			})
		},
		value1Change() {
			var _this = this;
			_this.$http.post(_this.craUrl + '/jchdCount', {
				departmentId: _this.value1
			}, {
				headers: {
					"token": _this.token
				}
			}).then(function (response) {
				if (response.data.code == 200) {
					_this.getEchar1(response.data.data)
				}
			})
		},
		value2Change() {
			var _this = this;
			_this.$http.post(_this.craUrl + '/statisticsJCTime', {
				departmentId: _this.value2
			}, {
				headers: {
					"token": _this.token
				}
			}).then(function (response) {
				if (response.data.code == 200) {
					_this.getEchar2(response.data.data)
				}
			})
		},
		value3Change() {
			var _this = this;
			_this.$http.post(_this.craUrl + '/auditHdCount', {
				departmentId: _this.value3
			}, {
				headers: {
					"token": _this.token
				}
			}).then(function (response) {
				if (response.data.code == 200) {
					_this.getEchar3(response.data.data)
				}
			})
		},
		value4Change() {
			this.getAuditHdCount()
		},
		getData1() {
			var _this = this;
			_this.$http.post(_this.craUrl + '/monthJCStatistics', {}, {
				headers: {
					"token": _this.token
				}
			}).then(function (res) {
				if (res.data.code == 200) {
					_this.data1 = res.data.data.data;
				}
			})
		},
		// 获取公司2019年季度人员监查工时统计排名
		getData2() {
			var _this = this;
			_this.$http.post(_this.craUrl + '/JCOrderByCra', {
				number: 5,
				page: '',
				craName: '',
				isAll: ''
			}, {
				headers: {
					"token": _this.token
				}
			}).then(function (res) {
				if (res.data.code == 200) {
					_this.data2 = res.data.data;
				}
			})
		},
		getData3() {
			var _this = this;
			_this.$http.post(_this.craUrl + '/quarterAuditStatistics', {}, {
				headers: {
					"token": _this.token
				}
			}).then(function (res) {
				if (res.data.code == 200) {
					_this.data3 = res.data.data.data;
				}
			})
		},
		getData4() {
			var _this = this;
			_this.$http.post(_this.craUrl + '/monthSpecialStatistics', {}, {
				headers: {
					"token": _this.token
				}
			}).then(function (res) {
				if (res.data.code == 200) {
					_this.data4 = res.data.data.array;
				}
			})
		},

		getData5() {
			var _this = this;
			_this.$http.post(_this.craUrl + '/enterForProStatistics', {}, {
				headers: {
					"token": _this.token
				}
			}).then(function (res) {
				if (res.data.code == 200) {
					_this.data5 = res.data.data.array;
				}
			})
		},
		getData6() {
			var _this = this;
			_this.$http.post(_this.craUrl + '/enterForProQuarter', {}, {
				headers: {
					"token": _this.token
				}
			}).then(function (res) {
				if (res.data.code == 200) {
					_this.data6 = res.data.data.array;
				}
			})
		},
		getData7() {
			var _this = this;
			_this.$http.post(_this.craUrl + '/yearJCTimeStatistics', {}, {
				headers: {
					"token": _this.token
				}
			}).then(function (res) {
				if (res.data.code == 200) {
					_this.data7 = res.data.data.statisticsList;
				}
			})
		},
		//点击跳转详情
		projectParticulars(id) {
			this.$router.push({
				name: 'inspectParticulars',
				params: {
					id: id
				}
			})
			//				console.log(id)
		},
		getEchar1(data) {
			let echar1 = this.$echarts.init(document.getElementById('echar-1'))
			echar1.setOption({
				tooltip: {
					trigger: 'axis',
					axisPointer: { // 坐标轴指示器，坐标轴触发有效
						type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
					}
				},
				legend: {
					data: ['单项目监查工时', '平台平均单项目监查工时']
				},
				grid: {
					left: '3%',
					right: '4%',
					bottom: '3%',
					containLabel: true
				},
				xAxis: {
					type: 'category',
					data: data.x,
				},
				yAxis: {
					type: 'value',
					min: 0,
					max: 20,
					interval: 5,
				},
				series: [{
					name: '单项目监查工时',
					type: 'bar',
					stack: '总量',
					barWidth: '70%',
					color: '#539CF2',
					label: {
						normal: {
							show: true,
							position: 'insideRight'
						}
					},
					data: data.y1
				}, {
					name: '平台平均单项目监查工时',
					type: 'line',
					stack: '总量',
					color: '#FD9F83',
					data: data.y2
				},]
			});
		},
		getEchar2(data) {
			let echar2 = this.$echarts.init(document.getElementById('echar-2'))
			echar2.setOption({
				tooltip: {
					trigger: 'axis',
					axisPointer: { // 坐标轴指示器，坐标轴触发有效
						type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
					}
				},
				legend: {
					data: ['项目名']
				},
				grid: {
					left: '3%',
					right: '4%',
					bottom: '3%',
					containLabel: true
				},
				xAxis: {
					type: 'category',
					data: data.x,
					axisLine: {
						lineStyle: {
							color: '##CBD1DA'
						}
					}

				},
				yAxis: {
					type: 'value',
					min: 0,
					max: 300,
					interval: 100,
					axisLine: {
						lineStyle: {
							color: '##CBD1DA'
						}
					}
				},
				series: [{
					name: '项目名',
					type: 'bar',
					barWidth: '70%',
					color: '#FFE188',
					label: {
						normal: {
							show: true,
							position: 'insideRight'
						}
					},
					data: data.y
				}]
			});
		},
		getEchar3(data) {
			let echar3 = this.$echarts.init(document.getElementById('echar-3'))
			echar3.setOption({
				tooltip: {
					trigger: 'axis',
					axisPointer: { // 坐标轴指示器，坐标轴触发有效
						type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
					}
				},
				legend: {
					data: ['单项目稽查工时', '平台平均单项目季度稽查工时']
				},
				grid: {
					left: '3%',
					right: '4%',
					bottom: '3%',
					containLabel: true
				},
				xAxis: {
					type: 'category',
					data: data.x,
					axisLine: {
						lineStyle: {
							color: '##CBD1DA'
						}
					}
				},
				yAxis: {
					type: 'value',
				},
				series: [{
					name: '单项目稽查工时',
					type: 'line',
					color: '#FEDA66',
					label: {
						normal: {
							show: true,
							position: 'insideRight'
						}
					},
					data: data.y1
				},
				{
					name: '平台平均单项目季度稽查工时',
					type: 'line',
					color: '#52D9DB',
					label: {
						normal: {
							show: true,
							position: 'insideRight'
						}
					},
					data: data.y2
				}
				]
			});
		},

		toOfficeList() {
			this.$router.push('/statistics1');
		},
		toOfficeProject() {
			this.$router.push('/statistics3')
		},
		toCrcProject(id) {
			this.$router.push({
				name: 'workingTime',
				params: {
					id: id
				}
			})
		},
		toMouthStatistics() {
			this.$router.push('/CramouthStatistics')
			this.$store.commit('newResult', '');
		},
		catCraDetails(id) {
			this.$router.push({
				name: "CraDetails",
				params: {
					id: id
				}
			});
		}
	}
}
